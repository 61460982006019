import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '38, 50, 56',
		'primary-dark': '0, 0, 0',
		'accent': '199, 220, 230',
		'accent-plus': '0, 0, 0',
	},
});
